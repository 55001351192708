<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;  position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section class="d-none d-sm-block d-lg-block d-xl-block">
      <div class="container-fluid pt-5 mt-5 g-0">
        <div class="row g-0">
          <div class="col text-center">
            <div class="about-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1 class="display-2"><strong>About us</strong></h1>
                  <h2 class="display-6">Let's get to know each other</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="d-block d-sm-block d-lg-none d-xl-none">
      <div class="container-fluid pt-3 g-0">
        <div class="row g-0 text-center">
          <img src="/images/about-banner.jpg" class="img-fluid">
        </div>
      </div>
    </section>

    <section class="sectionTwo pt-3">
      <div class="container g-0 pt-3">
        <div class="row g-0 ">
          <div class="col-lg-6 order-md-1 order-2">
            <div class="h-100"><img src="images/about-intro.jpg" class="mw-100" /></div>
          </div>
          <div class="col-md-5 p-5 align-self-center order-md-2 order-1">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>About us</strong></h2>
              <p>We live where we work, and we love where we live! With intimate knowledge of the Winelands and surrounding areas, our team is perfectly positioned to provide expert insight and service to buyers, sellers, tenants, renters, developers and investors.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionTwo bg-light">
      <div class="container g-0">
        <div class="row g-0">
          <div class="col-md-6 align-self-center pe-md-5 order-1 order-sm-2">
            <div class="h-100 p-5">
              <h2 class="switch-blue"><strong>Our community</strong></h2>
              <p>What started as a team of four has grown into a dynamic organisation of 15, reaching over R1 billion in sales in 2021 and 2022. And all in just five short years.</p>
              <p>We specialise in the Winelands and set the bar high in this beautiful corner of South Africa. But to us, it’s about more than just sealing real estate deals, and a large part of our business is focused on what happens beyond the transaction.</p> 
              <p>Our team is professional and energetic, and always focused on building trusted relationships with our customers. With quality, personalised service at our core, we help our customers bring their property dreams to life.</p>
            </div>
          </div>
          <div class="col-lg-6 order-md-2 order-1">
            <div class="h-100"><img src="images/about-community.jpg" class="mw-100" /></div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionTwo ">
      <div class="container g-0">
        <div class="row g-0">
          <div class="col-lg-6 order-2 order-md-1">
            <div class="h-100"><img src="images/about-why.jpg" class="mw-100"/></div>
          </div>
          <div class="col-md-5 p-5 align-self-center order-1 order-md-2">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>Why trust us?</strong></h2>
              <p>Luxury Winelands is part of the Chas Everitt International property group – South Africa’s leading real estate company of choice. Started in 1980, the business is built on a foundation of solid family values and a desire to not just serve our customers well, but to become their lifelong property counsellors by delivering truly memorable service.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionTwo bg-light">
      <div class="container g-0">
        <div class="row g-0 ">
          <div class="col-md-6 p-5 align-self-center">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4 pb-4"><strong>Our International Partnerships</strong></h2>
              <p>Chas Everitt International is proud to be a member of the prestigious Leading Real Estate Companies of the World® group and their exclusive Luxury Portfolio International group.</p>
              <p>These affiliations give our customers the advantage of having their properties exposed to the affluent international market.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="h-100"><img src="/images/joinus.png" class="mw-100"/></div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="pb-5">
      <div class="container g-0">
        <div class="row g-0 ">
          <div class="col-lg-6">
            <div class="h-100"><img src="images/about-awards.jpg" class="mw-100"/></div>
          </div>
          <div class="col-md-5 p-5 align-self-center">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>Our awards and accolades</strong></h2>
              <p>While our first commitment is always to our customers and delivering the highest-quality service, it’s nice to receive industry recognition. Some of our recent awards include: </p>
            </div>
          </div>
        </div>
        <div>
          <div class="row pt-5 pb-5">
            <div class="col-md-6">
              <p><strong>2024</strong></p>
              <i class="bi bi-trophy"></i> Young Lioness (Exemplifying the true spirit of lionism with excellence & enthusiasm of a true champion) - Renata Gomes & Sydne Louw<br/>
              <i class="bi bi-trophy"></i> Luxury Brand Builder (Consistently on top of your game in representing the Luxury brand in a positive light & raising brand awareness) - Paarl Lifestyle Estates<br/>
              <i class="bi bi-trophy"></i> Chas Cares Award (An honorable award for those who are making a significant contribution to their community through their time, actions, talents and dedication. You are a role model for compassion, service and passion.) - Anneke Buys<br/>
              <i class="bi bi-trophy"></i> Platinum Sales Teams (390 - 629)<br/>
              <i class="bi bi-trophy"></i> Diamond Rentals (420 plus)<br/>
              <i class="bi bi-trophy"></i> National Rental Agent Overall (#2 Ranked Paarl Lifestyle Estates Betro Groenewald & Aidan Litchfield)<br/>
              <i class="bi bi-trophy"></i> National New Entrant Sales (#2 Ranked Paarl Lifestyle Estates Erika McCormack)<br/>
              <i class="bi bi-trophy"></i> National value agent/partnership sales(#3 Ranked Paarl Lifestyle Estates Renee Lister-James & Erika McCormack, #2 Ranked Paarl Lifestyle Estates Amanda Colombo & Anneke Buys)<br/>
              <i class="bi bi-trophy"></i> National non-metro office (#1 Ranked Paarl Lifestyle Estates)<br/>
              <i class="bi bi-trophy"></i> National partnership sales (#1 Ranked Paarl Lifestyle Estates Amanda Colombo & Anneke Buys)<br/>
            </div>
            <div class="col-md-3">
              <p><strong>2023</strong></p>
              <i class="bi bi-trophy"></i> CEO Award<br/>
              <i class="bi bi-trophy"></i> Top Non-Metro Office<br/>
              <i class="bi bi-trophy"></i> Top Non-Metro Partnership<br/>
              <i class="bi bi-trophy"></i> 2nd Ranked Non-Metro Partnership<br/>
              <i class="bi bi-trophy"></i> 3rd Ranked Non-Metro Partnership<br/>
              <p class="mt-4"><strong>2019</strong></p>
              <i class="bi bi-trophy"></i> 2nd place – Top Turnover Sales<br/>
              <i class="bi bi-trophy"></i> Top Selling Agents in Pearl Valley and Val De Vie Estates<br/>
              <i class="bi bi-trophy"></i> Top Luxury Trendsetters<br/>
            </div>
            <div class="col-md-3">
              <p><strong>2020 & 2021</strong></p>
              <i class="bi bi-trophy"></i> Movers and Shakers Award<br/>
              <i class="bi bi-trophy"></i> National Top Value (Partnership)<br/>
              <i class="bi bi-trophy"></i> National Top Notebook Operations<br/>
              <i class="bi bi-trophy"></i> National Top Non-Metro Partnership<br/>
              <i class="bi bi-trophy"></i> Gold and Platinum Sales Award<br/>
              <i class="bi bi-trophy"></i> Gold and Platinum Rental Award<br/>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <Footer />
  
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>
<style scoped>
  
</style>